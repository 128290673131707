import { Component } from "react"

class Cv extends Component {
    render()
    {
        return (
            <iframe src="https://drive.google.com/file/d/109GHuySMuLL1vQEMYYd3B1SBnOmdL-a1/preview" width="100%" height="100%" allow="autoplay"></iframe>
        )
    }
}

export default Cv;
